// Variables

// Gray and Brand Colors for use across theme

$theme-primary:    #D27F1B;

$spacer: 1em;

$gray-base:        #000 !default;
$gray-darker:	   lighten($gray-base, 13.5%) !default; // #222
$gray-dark:		   lighten($gray-base, 20%) !default;   // #333
$gray:			   lighten($gray-base, 33.5%) !default; // #555
$gray-light:	   lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:	   lighten($gray-base, 93.5%) !default; // #eee

$p-font-sizes: (
  null  : 15px,
  480px : 16px,
  640px : 17px,
  1024px: 19px
);

$h1-font-sizes: (
  null  : 28px,
  480px : 38px,
  640px : 43px,
  1024px: 56px
);

$h2-font-sizes: (
  null  : 24px,
  480px : 29px,
  640px : 33px,
  1024px: 40px
);

$h3-font-sizes: (
  null  : 20px,
  480px : 25px,
  640px : 30px,
  1024px: 34px
);

$h4-font-sizes: (
  null  : 19px,
  480px : 23px,
  640px : 27px,
  1024px: 30px
);