// Mixins
// Bootstrap Button Variant
@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;
    &:focus,
    &.focus {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 25%);
    }
    &:hover {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);
        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }
    .badge {
        color: $background;
        background-color: $color;
    }
}

@mixin transition-all() {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}

@mixin background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

@mixin serif-font() {
    font-family: 'Raleway', sans-serif;
}

@mixin sans-serif-font() {
    font-family: 'Work sans', sans-serif;
}

// fonts
@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    }
    @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}